<template>
    <div>
        <div
            :class="{'addClass':addClass==1}"
            class="container">
            <div class="price">
                {{ price | formatMoney }}
            </div>
            <div
                :class="{
                    'adjust_width': price > 10000,
                    'normal': price <= 10000
                }"
                class="vip">
                VIP
            </div>
        </div>
    </div>
</template>

<script>
export default {
    filters: {
        formatMoney(num) {
            return num.toFixed(2);
        },
    },
    props: {
        price: {
            type: [Number, String],
            required: true,
        },
        addClass: {
            type: Number,
            require: false,
        },
    },
};
</script>

<style lang="scss" scoped>

.container {
    display: flex;
    height: 17px;
    line-height: 17px;

    .price {
        padding: 0 3px 0 4px;
        // min-width: 47px;
        font-size: 11px;
        // font-family: Avenir-Medium, Avenir;
        font-weight: 500;
        color: #fff;
        background: linear-gradient(141deg, #535353 0%, #262726 100%);
        border-top-left-radius: 13px;
        border-bottom-left-radius: 13px;

        &::before {
            content: '¥';
        }
    }

    .vip {
        font-size: 11px;
        // font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #343434;
        text-align: center;
        background: linear-gradient(138deg, #f4dbbd 0%, #f4c770 100%);
        border-top-right-radius: 13px;
        border-bottom-right-radius: 13px;

        &.normal {
            width: 24px;
        }

        &.adjust_width {
            width: 26px;
        }
    }
}
.addClass {
    height: 22px;
    line-height: 22px;
}
</style>
