<template>
    <div class="container">
        <div class="empty" />
        <div class="empty_text">
            <div v-if="type === 0">
                {{ tip }}
            </div>
            <div v-if="type === 1">
                <span>请</span>
                <span
                    @click="toCityList"
                    class="text_btn">选择城市</span>
            </div>
            <div v-if="type === 2">
                <p>您当前所在城市暂无药品信息，</p>
                <p>
                    <span>请手动</span>
                    <span
                        @click="toCityList"
                        class="text_btn">选择其他城市</span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: Number,
            default: 0,
        },
        tip: {
            type: String,
            default: '暂无数据',
        },
    },
    methods: {
        toCityList() {
            this.$router.push({
                name: 'city',
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    height: 100%;
    overflow: hidden;
}
.empty {
    position: relative;
    // margin-top: 123px;
    margin-top: 88px;
    width: 100%;
    height: 190px;
    background: url('~@img/empty.png') no-repeat;
    background-size: contain;
}
.empty_text {
    width: 100%;
    font-size: 14px;
    // font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #a4a8b2;
    line-height: 20px;
    text-align: center;
}
.text_btn {
    color: #3584f4;
}
</style>
