<template>
    <div
        @click="toCityList"
        class="city mr18">
        <span class="city_name">{{ cityInfo.cityName || '请选择' }}</span>
        <img
            :src="icon.arrow === 'white' ? require('@img/arrow_down_white.png') : require('@img/arrow_down_black.png')"
            class="icon_arrow ml3"
            alt="">
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: {
        icon: {
            type: Object,
            default: () => ({
                arrow: 'white',
            }),
        },
    },
    computed: {
        ...mapState({
            cityInfo: (store) => store.city.cityInfo,
        }),
    },
    created() {
        if (!Object.keys(this.cityInfo).length) {
            this.getCurrentCityInfo();
        }
    },
    methods: {
        ...mapActions('city', [
            'getCurrentCityInfo',
        ]),
        toCityList() {
            this.$router.push({
                name: 'city',
            });
        },
    },
};
</script>

<style lang='scss' scoped>
@import './index.scss';
</style>
