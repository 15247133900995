<template>
  <div>
    <div class="header pl18">
      <CityPosition :icon="{ arrow: 'black' }" />
      <div class="search">
        <Search
          ref="searchRef"
          v-model.trim="searchForm.searchKeyword"
          shape="round"
          show-action
          placeholder="搜索药品名称"
          @search="onSearch"
        >
          <template #left-icon>
            <div class="left_icon">
              <img
                :src="require('@img/icon_search_grey.png')"
                class="icon_search"
                alt=""
              />
            </div>
          </template>
          <template #action>
            <div
              :class="{ active: searchForm.searchKeyword.length }"
              class="btn"
              @click="onSearch"
            >
              搜索
            </div>
          </template>
        </Search>
      </div>
    </div>

    <div v-if="list.length">
      <List
        v-model="loading"
        :class="{ adjust_height: type === 'text' }"
        :finished="finished"
        finished-text="到底啦～"
      >
        <div
          v-for="(item, index) in list"
          :key="index"
          class="list_wrapper mt12 ml12 mr12 pl12 pr12"
        >
          <div>
            <div
              v-for="detail in item.goodsDetailCO"
              :key="detail.productId"
              @click="toDrugDetail(detail)"
            >
              <Drug-item :data="detail" :member-price="detail.memberPrice" />
            </div>
            <div
              v-ellipsis="1"
              class="store"
              @click.stop="handleStoreClick(item)"
            >
              <img class="store_logo" src="./store.png" />
              <span>{{ item.pharmacyInfo.fullName }}</span>
            </div>
          </div>
        </div>
      </List>
    </div>
    <div v-if="!list.length && !isFirstPage && cityInfo.cityCode">
      <Empty-data tip="未找到搜索结果" />
    </div>
    <div v-if="!cityInfo.cityCode">
      <EmptyData :type="2" tip="定位失败，请手动选择城市" />
    </div>
  </div>
</template>
<script>
import { List, Search } from 'vant';
import DrugItem from '@/components/order/drug-item';
import EmptyData from '@/components/empty-data';
import CityPosition from '@/components/city-position';
import api from '@/api';
import { mapState } from 'vuex';
import Configs from '@/config';

export default {
  components: {
    List,
    Search,
    DrugItem,
    EmptyData,
    CityPosition,
  },
  data () {
    return {
      list: [],
      loading: false,
      finished: true,
      type: '',
      pageNumber: 1,
      pageSize: 10,
      test: true,
      // 是否第一次进入页面, 决定空白无内容展示形式
      isFirstPage: true,
      searchForm: {
        searchKeyword: '九期一',
        cityId: '',
        provincesId: '',
      },
    };
  },
  computed: {
    ...mapState({
      cityInfo: (store) => store.city.cityInfo,
    }),
  },
  watch: {
    'cityInfo.cityCode': {
      handler (n, o) {
        console.log(this.cityInfo, n, o);
        if (!o) {
          this.getListData();
        }
      },
    },
  },
  created () {
    const { title, searchKey } = this.$route.params;
    if (title) {
      document.title = title;
    }
    if (searchKey) {
      this.searchForm.searchKeyword = searchKey;
    }
    if (this.cityInfo.cityCode) {
      this.getListData();
    }
  },
  mounted () {
    this.$nextTick(() => {
      document.getElementsByClassName('van-field__control')[0].focus();
    });
  },
  methods: {
    handleStoreClick (store) {
      this.$router.push({
        name: 'store',
        params: {
          merchantCode: store.pharmacyInfo.groupCode,
        },
      });
    },
    toDrugDetail (item) {
      this.$router.push({
        name: 'drug',
        params: {
          id: item.goodsId,
        },
      });
    },
    onSearch () {
      if (!this.searchForm.searchKeyword.length) return;
      this.list = [];
      this.getListData();
    },
    getListData () {
      this.isFirstPage = false;
      const { cityId, provincesId } = this.$store.state.city.cityInfo;
      console.log(this.$store.state.city.cityInfo);
      this.searchForm.cityId = cityId;
      this.searchForm.provincesId = provincesId;
      const channelCode = localStorage.getItem('channelCode')
      if (channelCode && channelCode === "PAK") { // 派安康来源
        this.searchForm['saleChannel'] = Configs.channelCode[channelCode.toUpperCase()]
      }
      api.getSearchResult(this.searchForm).then((res) => {
        const { list = [] } = res.data;
        // this.list = this.list.concat(list);
        this.list = list;
        // this.totalPage = totalPage;
        this.loading = false;
        console.log(this.list);
        // if (this.pageNumber >= this.totalPage) {
        //     this.finished = true;
        // }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@styles/variables.scss';
/deep/ .van-list__finished-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  width: auto;
  font-size: 11px;
  // font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #babfc9;
  line-height: 16px;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    margin: 0 8px;
    width: 40px;
    height: 1px;
    background: #dcdfe4;
    color: red;
  }
}
.header {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(60, 107, 240, 0.1);

  /deep/ .city_name {
    color: #62636c;
  }

  .search {
    flex: 1;

    .left_icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    .van-cell {
      padding: 0;
      line-height: 28px;
      font-size: 14px;
    }

    .van-search {
      padding: 5px 0;
    }

    /deep/ .van-field__body {
      padding-right: 4px;
    }

    .btn {
      width: 42px;
      font-size: 14px;
      // font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #cfcfd2;
      text-align: center;

      &.active {
        color: #3679f0;
      }
    }
  }
}
.van-list {
  // height: calc(100vh - 44px);
  overflow: scroll;
}
.adjust_height {
  height: calc(100vh - 44px);
}
.list_wrapper {
  box-shadow: 0 0 10px 0 rgba(54, 121, 240, 0.1);
  border-radius: 6px;
  background: #fff;
  .store {
    position: relative;
    width: 100%;
    height: 44px;
    // text-indent: 22px;
    line-height: 44px;
    color: $--color-title;
    font-size: 14px;
    font-weight: 500;
    .store_logo {
      top: 12px;
      width: 16px;
      height: 16px;
      margin-right: 6px;
      transform: translateY(2px);
    }
    &::after {
      position: absolute;
      top: 15px;
      right: 0;
      content: '';
      width: 12px;
      height: 12px;
      background-image: url('./arrow_right.png');
      background-size: 12px 12px;
    }
  }
}
</style>
