<template>
    <div
        @click="detail()"
        class="drug_item van-hairline--bottom">
        <div class="drug_img">
            <!-- <img
                :src="data.productImageUrl ? data.productImageUrl : require('@img/default.png')"
                alt=""> -->
            <img
                :src="getImgUrl(data) || require('@img/default.png')"
                alt="">
            <div
                v-if="data.prescription==1|| data.flagPrescription==1"
                class="tag">
                处方药
            </div>
        </div>

        <div class="item_info">
            <p
                v-ellipsis="2"
                class="title">
                {{ data.name||data.productName }}{{ ' ('+ data.usualName+')' }}
            </p>
            <p
                v-if="showSpes"
                class="spes">
                <span>规格: &nbsp;</span>
                <span>{{ data.specsValue||data.specs }}</span>
            </p>
            <p
                v-else
                class="no" />
            <div class="mon_count">
                <div class="price_container">
                    <!-- 正常价格显示 -->
                    <p
                        v-if="isPay"
                        class="money">
                        ￥{{ changeFixed(data.salePrice) || changeFixed(data.productPrice) }}
                    </p>
                    <!-- 会员价和正常价格显示 -->
                    <p
                        v-else
                        class="money">
                        <span>￥{{ changeFixed(data.memberPrice) }}</span>
                        <span class="origin_price">￥{{ changeFixed(data.salePrice) || changeFixed(data.productPrice) }}</span>
                    </p>
                </div>
                <slot name="numOrCount" />
            </div>
            <div v-if="memberPrice > 0 ">
                <MemberPrice :price="memberPrice" />
            </div>
        </div>
    </div>
</template>

<script>
import MemberPrice from '@/components/member-price';

export default {
    name: 'DrugItem',
    components: {
        MemberPrice,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        showSpes: {
            type: Boolean,
            default: true,
        },
        isPay: {
            type: Boolean,
            default: true,
        },
        memberPrice: {
            type: Number,
            default: 0,
        },

    },
    computed: {
        getImgUrl() {
            return (data) => {
                if (data.productImageUrl === null || data.productImageUrl === '{}') {
                    return false;
                } if (data.productImageUrl && data.productImageUrl.length) {
                    return data.productImageUrl instanceof Array ? data.productImageUrl[0] : data.productImageUrl;
                }
                if (data.productImages === null || data.productImages === '{}') {
                    return false;
                } if (data.productImages && data.productImages.length) {
                    return data.productImages[0];
                }

                if (data.picture === null || data.picture === '{}' || data.picture === '[]') {
                    return false;
                } if (data.picture) {
                    return data.picture.split('|')[0];
                }
                return false;
            };
        },
    },
    methods: {
        changeFixed(data) {
            return data ? Number(Number(data) / 100).toFixed(2) : 0;
        },
        detail() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
.drug_item {
    display: flex;
    flex-direction: row;
    padding: 12px 0 12px;
    .drug_img {
        width: 78px;
        height: 78px;
        margin-right: 12px;
        // padding: 7px 5px 5px 6px;
        background: #edf1f8;
        border-radius: 4px;
        position: relative;
        img {
            width: 78px;
            height: 78px;
            border-radius: 4px;
            // width: 57px;
            // height: 56px;
        }
        .tag {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 44px;
            height: 16px;
            line-height: 16px;
            color: #fff;
            text-align: center;
            background: url('~@img/tag_bg.png') no-repeat;
            background-size: contain;
        }
    }
    .item_info {
        padding-top: 3px;
        width: 100%;
        .title {
            // height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #333;
            line-height: 20px;
            margin-bottom: 4px;
        }
        .spes {
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #62636c;
            line-height: 17px;
            margin-bottom: 4px;
        }
        .no {
            height: 7px;
        }
        .mon_count {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: -4px;

            .price_container {
                display: flex;
            }

            .money {
                height: 19px;
                font-size: 14px;
                font-weight: 500;
                color: #e94947;
                line-height: 19px;
            }
            .origin_price {
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.35);
                margin-left: 15px;
                text-decoration: line-through;
            }
        }
    }
}

</style>
